<template>
    <el-form ref="form" :model="form" label-width="200px" class="elForm" :rules="rules">
      <el-form-item label="推广奖励金额：">
        <el-input v-model="form.invite_artisan_award_amount" type="number" min="0.00" step="0.01" placeholder="请输入推广奖励金额" style="width: 600px"><template slot="append">元</template></el-input> 技工分享邀请入驻技工成功，奖励金额
      </el-form-item>
      <el-form-item label="	技工入驻保证金：">
        <el-input v-model="form.artisan_earnest_money" type="number" min="0.00" step="0.01" placeholder="请输入技工入驻保证金" style="width: 600px"><template slot="append">元</template></el-input> 技工入驻缴纳保证金
      </el-form-item>
      <el-form-item label="	平台抽佣：">
        <el-input v-model="form.rate" type="number" min="0.00" max="100.00" step="0.01" placeholder="请输入平台抽佣" style="width: 600px"><template slot="append">%</template></el-input> 平台对入驻商家，物业，入驻技工的平台抽佣比例
      </el-form-item>
      <el-form-item label="广告费用：">
        <el-input v-model="form.ad_day_cost" type="number" min="0.00" step="0.01" placeholder="请输入广告费用" style="width: 600px"><template slot="append">元/天</template></el-input> 商家申请广告费用，按天收费
      </el-form-item>
      <Preservation @preservation="preservation"></Preservation>
    </el-form>
  </template>
  <script>

  import _ from 'lodash';
  import Preservation from '@/components/preservation';

  export default {
    components: {
      Preservation,
    },
    data() {
      return {
        form: {
            invite_artisan_award_amount: 0,
            artisan_earnest_money: 0,
            ad_day_cost: 0,
            rate: 0,
        },
      };
    },
    created() {
      this.getInfo();
    },
    methods: {
        getInfo(){
            this.$axios.post(this.$api.repair.BasicsSet.info, this.user_form).then(res => {
            if (res.code == 0) {
                if(res.result){
                    this.form = res.result;
                }
            }else{
                this.$message.error(res.msg);
            }
          });
      },
      preservation() {
        let data = {
            invite_artisan_award_amount: this.form.invite_artisan_award_amount,
            artisan_earnest_money: this.form.artisan_earnest_money,
            ad_day_cost: this.form.ad_day_cost,
            rate: this.form.rate,
        };
        this.$axios.post(this.$api.repair.BasicsSet.edit, data).then(res => {
          if (res.code == 0) {
            this.$message.success('保存成功');
            this.getInfo();
          } else {
            this.$message.error(res.msg);
          }
        });
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  span {
    color: gray;
  }
  .elForm {
    background-color: #fff;
    padding: 30px;
  }
  </style>
  